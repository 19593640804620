import { useMemo } from "react"

import { fromUnixTime, getUnixTime } from "date-fns"

import { IGraphDateRange } from "src/components/Homes/DeviceDetails/Overview/DeviceGraphs"
import {
  useFetchDeviceMotionReadings,
  useFetchDeviceReadings,
} from "src/data/devices/queries/deviceQueries"
import {
  DeviceReadingType,
  IDeviceReadings,
  TDevice,
} from "src/data/devices/types/deviceTypes"
import { monitoringAvailable } from "src/data/homes/logic/homeUtil"
import { IHome, MonitoringType } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { getTemperatureUnitWithFallback } from "src/data/user/logic/userTemperature"
import { useFlags } from "src/hooks/useFlags"
import {
  extractActiveNoiseThreshold,
  extractTemperatureThreshold,
} from "src/ui/Graphs/configurationUtils"
import { extractHumidityThreshold } from "src/ui/Graphs/configurationUtils"
import { getMotionResolution, getResolution } from "src/ui/Graphs/utils"
import { toFahrenheit } from "src/utils/l10n"
import { utcToHomeTimezone } from "src/utils/l10n"

export function useGetGraphData({
  dateRange,
  device,
  home,
}: {
  dateRange: IGraphDateRange
  device: TDevice
  home: IHome
}) {
  const user = useGetUser()
  const { showOutdoorMotionGraph } = useFlags()
  const { orgId } = useOrganization()
  const motionBucketSize = getMotionResolution(dateRange)
  const temperatureUnit = getTemperatureUnitWithFallback(user)

  const fetchDeviceMotionReadings = useFetchDeviceMotionReadings({
    orgId,
    deviceId: device.device_id,
    includeMinMax: true,
    startAt: dateRange.startDate.toISOString(),
    endAt: dateRange.endDate.toISOString(),
    timeResolution: motionBucketSize,
  })

  const fetchDeviceReadingsSound = useFetchDeviceReadings(
    createDeviceReadingsRequestBody({
      orgId,
      type: "sound_level",
      dateRange,
      deviceId: device.device_id,
    })
  )

  const fetchDeviceReadingsTemp = useFetchDeviceReadings(
    createDeviceReadingsRequestBody({
      orgId,
      type: "temperature",
      dateRange,
      deviceId: device.device_id,
    })
  )

  const fetchDeviceReadingsHumidity = useFetchDeviceReadings(
    createDeviceReadingsRequestBody({
      orgId,
      type: "humidity",
      dateRange,
      deviceId: device.device_id,
    })
  )

  const soundData: TGraphData = useMemo(() => {
    const data = fetchDeviceReadingsSound.data

    if (!data) {
      return null
    }

    return [
      data.values.map((dt) => ({
        ...dt,
        datetime: utcToHomeTimezone(dt.datetime, home.timezone),
        datetimeRaw: dt.datetime,
      })),
    ]
  }, [fetchDeviceReadingsSound.data, home.timezone])

  const humidityData: TGraphData = useMemo(() => {
    const data = fetchDeviceReadingsHumidity.data

    if (!data) {
      return null
    }

    return [
      data.values.map((dt) => ({
        ...dt,
        datetime: utcToHomeTimezone(dt.datetime, home.timezone),
        datetimeRaw: dt.datetime,
      })),
    ]
  }, [fetchDeviceReadingsHumidity.data, home.timezone])

  const motionData = useMemo(() => {
    const data = fetchDeviceMotionReadings.data

    return (
      data?.values?.map((dt): [number, number] => [
        getUnixTime(utcToHomeTimezone(fromUnixTime(dt[0]), home.timezone)),
        dt[1],
      ]) || []
    )
  }, [home.timezone, fetchDeviceMotionReadings.data])

  const temperatureData: TGraphData = useMemo(() => {
    const data = fetchDeviceReadingsTemp.data

    if (!data) {
      return null
    }

    return [
      transformTemperatureValues({
        values: fetchDeviceReadingsTemp.data.values,
        temperatureUnit,
        timezone: home.timezone,
      }),
    ]
  }, [fetchDeviceReadingsTemp.data, temperatureUnit, home.timezone])

  const showMotionGraph =
    showOutdoorMotionGraph ||
    monitoringAvailable({
      type: MonitoringType.MOTION,
      home,
      devices: [device],
    })

  const activeNoiseThreshold = extractActiveNoiseThreshold(
    device.configuration,
    !!home.disturbance_monitoring?.disturbance_monitoring_active
  )
  const temperatureThresholds = extractTemperatureThreshold(
    device.configuration,
    temperatureUnit
  )

  const humidityThresholds = extractHumidityThreshold(device.configuration)

  const timeDomain = [
    utcToHomeTimezone(dateRange.startDate.toISOString(), home.timezone),
    utcToHomeTimezone(dateRange.endDate.toISOString(), home.timezone),
  ]

  return {
    sound: {
      isLoading: fetchDeviceReadingsSound.isLoading,
      data: soundData,
      thresholds: activeNoiseThreshold,
    },
    temperature: {
      isLoading: fetchDeviceReadingsTemp.isLoading,
      data: temperatureData,
      unit: temperatureUnit,
      thresholds: temperatureThresholds,
    },
    humidity: {
      isLoading: fetchDeviceReadingsHumidity.isLoading,
      data: humidityData,
      thresholds: humidityThresholds,
    },
    motion: {
      isLoading: fetchDeviceMotionReadings.isLoading,
      data: motionData,
      hidden: !showMotionGraph,
      bucketSize: motionBucketSize,
    },
    timeDomain,
  }
}

function createDeviceReadingsRequestBody({
  orgId,
  type,
  dateRange,
  deviceId,
}: {
  orgId: string
  type: DeviceReadingType
  dateRange: IGraphDateRange
  deviceId: TDevice["device_id"]
}) {
  return {
    orgId,
    type,
    deviceId,
    includeMinMax: true,
    startAt: dateRange.startDate.toISOString(),
    endAt: dateRange.endDate.toISOString(),
    timeResolution: getResolution(dateRange),
  }
}

function transformTemperatureValues({
  values,
  temperatureUnit,
  timezone,
}: {
  values: IDeviceReadings["values"]
  temperatureUnit: "C" | "F"
  timezone?: IHome["timezone"]
}) {
  function convertTemperature(value: number | undefined) {
    if (value === undefined) {
      return undefined
    }

    if (temperatureUnit === "C") {
      return value
    }

    return Number(toFahrenheit(value, 2))
  }

  return values.map((value) => ({
    ...value,
    value: convertTemperature(value.value),
    min: convertTemperature(value.min),
    max: convertTemperature(value.max),
    datetime: utcToHomeTimezone(value.datetime, timezone),
    datetimeRaw: value.datetime,
  }))
}

export type TGraphData =
  | {
      value: number | undefined
      min?: number
      max?: number
      datetime: Date
      datetimeRaw: Date | string
    }[][]
  | null
